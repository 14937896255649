import React, { useEffect, useState, useRef } from 'react';
// import { Player } from '@lottiefiles/react-lottie-player';
import dynamic from 'next/dynamic';
const Player = dynamic(()=>import('@lottiefiles/react-lottie-player').then((mod) => mod.Player));
import { useInView } from 'react-intersection-observer';

const LottieAnimation = ({ lottieFile, ...other }) => {
  const lottieRef = useRef();
  const [ref, inView] = useInView({
    threshold: 1
  });

  useEffect(() => {
    if (lottieRef?.current?.play) {
      if (inView) {
        lottieRef.current.play();
      } else {
        lottieRef.current.pause();
      }
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <Player
        src={lottieFile}
        ref={lottieRef}
        autoplay={true}
        keepLastFrame={true}
        loop={true}
        rendererSettings={{
          hideOnTransparent: true,
          progressiveLoad: true
        }}
        {...other}
      />
    </div>
  );
};

export default LottieAnimation;
