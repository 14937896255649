export const getSectionPadding = (props={}) => {
  let { paddingTop, paddingBottom } = props;
  if (!paddingTop) paddingTop = 32;
  if (!paddingBottom) paddingBottom = 32;

  const mobile = {
    0: 0,
    16: 12,
    28: 16,
    32: 20,
    40: 24,
    48: 28
  };

  return `pt-${mobile[paddingTop]} pb-${mobile[paddingBottom]} lg:pt-${paddingTop} lg:pb-${paddingBottom}`;
};

export default getSectionPadding;
