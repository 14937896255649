import React from 'react';
import Head from 'next/head';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useRouter } from 'next/router';
import { useFetch } from 'use-http';

const NEXT_PUBLIC_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://www.proofserve.com';
const removeTags = (str = '') => (typeof str === 'string' ? str.replace(/(<([^>]+)>)/gi, '') : '');

export default function JsonLd({ seoTitle, seoDescription, mastheadTitle, mastheadDescription, page, prices }) {
  return seoTitle && page && page?.faQsCollection?.items ? (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
        [
          ${JSON.stringify(
            Service({
              title: seoTitle,
              description: seoDescription,
              prices
            })
          )}
          ,

          ${JSON.stringify(LegalService())}
          ,

          ${JSON.stringify(
            WebPage({
              title: mastheadTitle,
              description: mastheadDescription
            })
          )}
          ,

          ${JSON.stringify(BreadcrumbList())}
          ,

          ${JSON.stringify(
            FAQs({
              faqs: page?.faQsCollection?.items?.map(itm => ({
                question: itm.question,
                answer: documentToHtmlString(itm.answer.json)
              }))
            })
          )}
        ]
      `
        }}
      />
    </Head>
  ) : null;
}

export const Service = ({ title, description, prices }) => {
  const router = useRouter();
  const fullPath = `${NEXT_PUBLIC_SITE_URL}${router.asPath}`;

  return {
    '@context': 'http://schema.org',
    '@type': 'Service',
    provider: [
      {
        '@id': `${fullPath}#Organization`
      }
    ],
    url: [fullPath],
    name: [removeTags(title)],
    description: [removeTags(description)]
    // TODO: tie in prices with offers
    // offers: [
    //   {
    //     availability: ['https://schema.org/InStock'],
    //     priceCurrency: ['USD'],
    //     '@type': 'Offer',
    //     description: ['Standard dispatch to process servers', 'Includes 4-6 standard attempts', 'Outstanding online and phone support'],
    //     url: [NEXT_PUBLIC_SITE_URL + '/get-started'],
    //     name: ['Standard'],
    //     price: [85.0]
    //   }
    // ]
  };
};

export const LegalService = () => {
  const router = useRouter();
  const fullPath = `${NEXT_PUBLIC_SITE_URL}${router.asPath}`;
  return {
    '@context': 'http://schema.org',
    '@type': 'LegalService',
    '@id': `${fullPath}#Organization`,
    description: [
      'A single platform to save legal teams loads of time. Create serve requests across all 50 states and follow progress with live updates, keeping track of everything along the way.'
    ],
    url: [NEXT_PUBLIC_SITE_URL + '/'],
    name: ['Proof'],
    telephone: ['(303) 578-8682'],
    logo: [
      {
        name: ['Proof'],
        contentUrl: [NEXT_PUBLIC_SITE_URL + '/images/proof-logo.svg'],
        '@type': 'ImageObject',
        url: [NEXT_PUBLIC_SITE_URL + '/images/proof-logo.svg']
      }
    ],
    sameAs: ['https://www.linkedin.com/company/proofapp/', 'https://twitter.com/legalproof', 'https://en-gb.facebook.com/Proofserve/'],
    address: [
      {
        streetAddress: ['1800 N Gaylord St'],
        '@type': 'PostalAddress',
        postalCode: ['80206'],
        addressCountry: [
          {
            name: ['United States'],
            '@type': 'Country'
          }
        ],
        addressRegion: ['CO'],
        addressLocality: ['Denver']
      }
    ],
    priceRange: ['$$$'],
    image: [
      {
        name: ['Proof'],
        contentUrl: [NEXT_PUBLIC_SITE_URL + '/images/proof-logo.svg'],
        '@type': 'ImageObject',
        url: [NEXT_PUBLIC_SITE_URL + '/images/proof-logo.svg']
      }
    ]
  };
};

export const BreadcrumbList = () => {
  function makeTitle(slug) {
    const words = slug.split('-');

    for (var i = 0; i < words.length; i++) {
      const word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(' ');
  }

  const router = useRouter();
  const fullPath = `${NEXT_PUBLIC_SITE_URL}${router.asPath}`;
  const splitPaths = router.asPath.split('/').filter(Boolean);

  const createPaths = () => {
    let i = 1;
    const paths = [
      {
        '@type': 'ListItem',
        item: [NEXT_PUBLIC_SITE_URL + ''],
        name: ['Home'],
        position: [i]
      }
    ];

    splitPaths.forEach(path => {
      i += 1;
      const title = makeTitle(path);

      paths.push({
        '@type': 'ListItem',
        item: [NEXT_PUBLIC_SITE_URL + `/${splitPaths.slice(0, i - 1).join('/')}`],
        name: [title],
        position: [i]
      });
    });

    return paths;
  };

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    '@id': `${fullPath}#BreadcrumbList`,
    itemListElement: createPaths()
  };
};

export const WebPage = ({ title, description, image }) => {
  const router = useRouter();
  const fullPath = `${NEXT_PUBLIC_SITE_URL}${router.asPath}`;
  return {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    '@id': `${fullPath}#Webpage`,
    name: [removeTags(title)],
    description: [removeTags(description)],
    url: [fullPath],
    breadcrumb: [
      {
        '@id': `${fullPath}#BreadcrumbList`
      }
    ],
    sourceOrganization: [
      {
        '@id': `${fullPath}#Organization`
      }
    ],
    // hasPart: [
    //   {
    //     '@id': `${fullPath}#SiteNav`
    //   }
    // ],
    speakable: [
      {
        xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
        '@type': 'SpeakableSpecification',
        url: [fullPath]
      }
    ],

    image: [
      {
        '@type': 'ImageObject',
        name: ['How it works'],
        contentUrl: [NEXT_PUBLIC_SITE_URL + '/images/track.svg'],
        url: [NEXT_PUBLIC_SITE_URL + '/images/track.svg']
      }
    ]
  };
};

export const Review = ({ name, company, review }) => {
  const router = useRouter();
  const fullPath = `${NEXT_PUBLIC_SITE_URL}${router.asPath}`;
  return {
    '@context': 'http://schema.org',
    '@type': 'Review',
    author: [
      {
        name: [name],
        worksFor: [company],
        '@type': 'Person'
      }
    ],
    reviewBody: [review],
    itemReviewed: [
      {
        '@id': `${fullPath}#Organization`
      }
    ]
  };
};

export const FAQs = ({ faqs = [] }) => {
  const router = useRouter();
  const fullPath = `${NEXT_PUBLIC_SITE_URL}${router.asPath}`;

  const faqItem = ({ question, answer }) => ({
    '@type': 'Question',
    name: [question],
    acceptedAnswer: [
      {
        '@type': 'Answer',
        text: [answer]
      }
    ]
  });

  return faqs.length > 0
    ? {
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        isPartOf: [
          {
            '@id': `${fullPath}#Webpage`
          }
        ],
        '@context': 'http://schema.org',
        mainEntity: faqs.filter(({ question, answer }) => question && answer).map(item => faqItem(item))
      }
    : null;
};

export const SiteNavigationElement = () => {
  const router = useRouter();
  const fullPath = `${NEXT_PUBLIC_SITE_URL}${router.asPath}`;

  const menu = [
    {
      link: '/how-it-works',
      title: 'How It Works'
    },
    {
      link: '/for-law-firms',
      title: 'Why Proof',
      subItems: [
        {
          link: '/for-law-firms',
          title: 'For Law Firms'
        },
        {
          link: '/for-collections-agencies',
          title: 'For Collections'
        },
        {
          link: '/for-individuals',
          title: 'For Individuals'
        },
        {
          link: '/for-government',
          title: 'For Government'
        },
        {
          link: '/for-process-serving-companies',
          title: 'For Companies'
        },
        {
          link: '/for-servers',
          title: 'For Servers'
        },
        {
          link: '/for-law-enforcement',
          title: 'For Law Enforcement'
        }
      ]
    },
    {
      link: '/pricing',
      title: 'Pricing'
    },
    {
      link: '/learn',
      title: 'Resources',
      subItems: [
        {
          link: '/learn',
          title: 'Blog'
        },
        {
          link: '/about#press',
          title: 'Press'
        },
        {
          link: 'https://developer.proofserve.com/#introduction',
          title: 'API Docs'
        },
        {
          link: '/contact',
          title: 'Contact Us'
        },
        {
          link: '/service-areas',
          title: 'Service Areas'
        },
        {
          link: 'https://status.proofserve.com/',
          title: 'Status'
        },
        {
          link: '/success-stories',
          title: 'Success Stories'
        }
      ]
    },
    {
      link: '/integrations/clio',
      title: 'Apps'
    },
    {
      link: '/calculator/firm',
      title: 'Value Calculator',
      alignment: 'vertical',
      subItems: [
        {
          link: '/calculator/firm',
          title: 'Firm'
        },
        {
          link: '/calculator/property-management',
          title: 'Property Management'
        },
        {
          link: '/calculator/collection',
          title: 'Collections'
        }
      ]
    }
  ];
  return {
    '@context': 'http://schema.org',
    '@type': 'SiteNavigationElement',
    name: ['Proof - SiteNavigation'],
    '@id': `${fullPath}#SiteNav`,
    '@context': 'http://schema.org'
    // TODO: get nav info
    // mainEntity: [
    //   {
    //     name: ['Company'],
    //     numberOfItems: [6],
    //     '@type': 'ItemList',
    //     itemListElement: [
    //       {
    //         name: ['About Us'],
    //         position: [1],
    //         '@type': 'ListItem',
    //         url: [NEXT_PUBLIC_SITE_URL + '/about']
    //       },
    //       {
    //         name: ['Reviews'],
    //         position: [2],
    //         '@type': 'ListItem',
    //         url: [NEXT_PUBLIC_SITE_URL + '/reviews']
    //       },
    //       {
    //         name: ['Join our Team'],
    //         position: [3],
    //         '@type': 'ListItem',
    //         url: [NEXT_PUBLIC_SITE_URL + '/job-openings/']
    //       },
    //       {
    //         name: ['In The News'],
    //         position: [4],
    //         '@type': 'ListItem',
    //         url: [NEXT_PUBLIC_SITE_URL + '/in-the-news']
    //       },
    //       {
    //         name: ['Contact us'],
    //         position: [5],
    //         '@type': 'ListItem',
    //         url: [NEXT_PUBLIC_SITE_URL + '/contact']
    //       },
    //       {
    //         name: ['Events'],
    //         position: [6],
    //         '@type': 'ListItem',
    //         url: [NEXT_PUBLIC_SITE_URL + '/events']
    //       }
    //     ]
    //   },
    //   {
    //     name: ['partners'],
    //     numberOfItems: [2],
    //     '@type': 'ItemList',
    //     itemListElement: [
    //       {
    //         name: ['serve with us'],
    //         position: [1],
    //         '@type': 'ListItem',
    //         url: [NEXT_PUBLIC_SITE_URL + '/serve']
    //       },
    //       {
    //         name: ['process server resources'],
    //         position: [2],
    //         '@type': 'ListItem',
    //         url: [NEXT_PUBLIC_SITE_URL + '/partnerresources']
    //       }
    //     ]
    //   }
    // ]
  };
};


export function getRatingsSchema() {
  const googleReviewsFetch = useFetch('/api/get-google-reviews', {}, []);
  const googleReviews = googleReviewsFetch?.data;
  const ratings = googleReviews?.map(r => r.rating);
  const averageRating = ratings?.reduce((a,b) => (a+b), 0) / ratings?.length;
  const maxRating = ratings?.reduce((a, b) => Math.max(a, b), 0);


  const jsonReview = (rs) => {
    const reviewItem = {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: `"${rs.rating}"`
      },
      datePublished: rs?.date,
      reviewBody: rs?.snippet?.replace('"', "'")
    };

    if (rs?.user?.name?.length > 0) {
      reviewItem.author = {
        '@type': 'Person',
        name: rs.user.name
      };
    }

    return reviewItem;
  }
  return googleReviews?.length > 0 ? (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
          {
            "@context": "https://schema.org/",
            "@type": "Organization",
            "description": "Join Proof and get instant access to our nationwide network of vetted process servers. Our reach extends throughout all 50 states.",
            "name": "Proof",
            "review": ${JSON.stringify(googleReviews.map(jsonReview))},
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "${averageRating}",
              "bestRating": "${maxRating}",
              "ratingCount": "${googleReviews.length}"
            }
          }
          `
        }}
        />
    </Head>
  ):null;
}