import { useState, useEffect } from 'react';
import { getStoryblokApi } from '@storyblok/react';

const useBlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const getBlogPosts = async () => {
      const storyblokApi = getStoryblokApi();
      const params= {
        per_page:100,
        // starts_with: 'learn/',
        is_startpage: false,
        resolve_relations: ["post.category", "post.relatedPosts"],
        filter_query: {
          component:{
            in: "post"
          }
        }
      };

      if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
        params.cv = Date.now();
      }

      const { data } = await storyblokApi.get(`cdn/stories`, params);

      setBlogPosts(data.stories.map(rs => {
        if (!rs?.content?.category?.name?.length) {
          rs.content.category = {
            name:"Article",
            slug: "article",
            full_slug:"learn/category/article"
          }
        }
        return rs;
      }));
    };

    getBlogPosts();
  }, []);

  return blogPosts;
};

export default useBlogPosts;

export const useFeaturedPosts = ({random = false }) => {
  const posts = useBlogPosts();
  const featuredPosts = posts ? posts?.filter(p => p?.content?.isFeatured) : [];

  if (random) {
    return featuredPosts?.sort(() => Math.random() - 0.5);
  } else {
    return featuredPosts;
  }
};