import React from 'react'
import { storyblokEditable } from '@storyblok/react';

export default function InlineStats({ blok }) {
  return blok.stats?.length > 0 ? (
    <div className="flex flex-wrap -ml-12 -mb-6 text-center lg:text-left">
      {blok.stats.map(item => (
        <div className="pl-12 flex-auto pb-6" key={item._uid} {...storyblokEditable(blok)}>
          <div className="text-yellow font-medium text-28px mb-1" dangerouslySetInnerHTML={{ __html: item.heading }}/>
          <div dangerouslySetInnerHTML={{ __html: item.content }}/>
        </div>
      ))}
    </div>
  ) : null
}
