import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function useRouteHash() {
  const router = useRouter();
  const [hash, setHash] = useState('');

  useEffect(() => {
    // Function to update hash
    const updateHash = () => {
      const newHash = window.location.hash;
      setHash(newHash);
    };

    // Set initial hash
    updateHash();

    // Listen for hash changes
    window.addEventListener('hashchange', updateHash);

    // Listen for route changes
    router.events.on('routeChangeComplete', updateHash);

    // Cleanup
    return () => {
      window.removeEventListener('hashchange', updateHash);
      router.events.off('routeChangeComplete', updateHash);
    };
  }, [router.events]);

  return hash;
}