import React from 'react'
import { storyblokEditable } from '@storyblok/react';
import QuestionItem from '~/components/QuestionItem';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  section.bg-dark-blue {
    .question-item {
      border-color: rgba(255,255,255,0.1)!important;
    }

    svg.question-item-icon path {
      fill:#F3B545;
    }
  }
`

const StyledDiv = styled.div`
.prose,
.prose a {
  color: inherit;
}
.prose li:before {
  display:none!important;
}

`

export default function FaqItem({ blok }) {
  return (
    <StyledDiv {...storyblokEditable(blok)}>
      <QuestionItem question={blok.question} answer={blok.answer} isStoryblok={true} i={-2} arr={[]} />
      <GlobalStyle />
    </StyledDiv>
  )
}
