import React from 'react';
import Image from 'next/image';
import EbookForm from './EbookForm';
import useStore from '~/hooks/useStore';
import HubspotForm from '~/components/Forms/HubspotForm';

export default function EbookModalForm({ blok }) {
  const page = useStore(s => s.page);
  const [externalSubmitted, setExternalSubmitted] = React.useState(false);

  return (
    <div className="max-w-5xl my-12 mx-5 bg-white overflow-hidden" style={{ borderRadius: '5px' }}>
      <div className="grid md:grid-cols-2">
        <div className="p-6 md:p-10">
          {!externalSubmitted ? (
            <div className="space-y-4">
              <h3 className="u-h3">{blok.heading}</h3>
              <div className="text-18px" dangerouslySetInnerHTML={{ __html: blok.content }} />
            </div>
          ) : null}

          <div className={externalSubmitted ? '' : 'mt-12'}>
            {page.content.body[0].landingPageBlock[0]?.form[0]?.hubspotFormId?.length > 0 ? (
              <div><HubspotForm hubspotFormId={page.content.body[0].landingPageBlock[0]?.form[0]?.hubspotFormId}/></div>
            ) : (
              <EbookForm reduced={false} blok={page.content.body[0].landingPageBlock[0]} setExternalSubmitted={setExternalSubmitted} />
            )}
          </div>
        </div>
        {blok?.image?.filename?.length > 0 ? (
          <div className="relative bg-tertiary">
            <Image layout={'fill'} objectFit="contain" draggable="false" src={blok?.image?.filename} alt={blok?.image?.alt} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
