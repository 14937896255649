import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import Media from '~/components/Storyblok/elements/Media';
import getBackgroundColor from '~/utils/get-background-color';
import getSectionPadding from '~/utils/get-section-padding';

const Section = ({ blok }) => {
  const { background, backgroundDesktop, backgroundImage, backgroundImageDesktop, rounded = false, className = '', id="" } = blok;
  const padding = getSectionPadding(blok);

  return (
    <section
      className={`${padding} relative ${getBackgroundColor(background)} ${getBackgroundColor(backgroundDesktop, 'md')} ${rounded ? 'rounded-md overflow-hidden' : ''} ${className}`}
      id={id}
      {...storyblokEditable(blok)}>
      {backgroundImage && backgroundImage.filename && (
        <div className="absolute inset-0 pointer-events-none">
          <Media className="h-full" layout="fill" objectFit="cover" objectPosition={`50% 20%`} blok={{ asset: backgroundImage }} />
        </div>
      )}
      {backgroundImageDesktop && backgroundImageDesktop.filename && (
        <div className="absolute inset-0 pointer-events-none hidden lg:block">
          <Media className="h-full" layout="fill" objectFit="cover" objectPosition={`50% 20%`} blok={{ asset: backgroundImageDesktop }} />
        </div>
      )}

      <div className="container relative">
        {blok.section.map(innerBlok => (
          <StoryblokComponent blok={innerBlok} key={blok._uid + innerBlok._uid} />
        ))}
      </div>
    </section>
  );
};

export default Section;
