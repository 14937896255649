import React from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

export default function Buttons({ blok }) {
  return (
    <div className="buttons" {...storyblokEditable(blok)}>
      <div className={`-ml-6 -mt-4 ${blok.display === 'inline'? 'sm:flex items-center flex-wrap' : ''}`}>
        {blok.button.map(button => (
          <div className="pl-6 pt-4 w-full sm:w-auto" key={button._uid}>
            <StoryblokComponent blok={button}  />
          </div>
        ))}
      </div>
    </div>
  );
}
