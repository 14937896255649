export const getBackgroundColor = (color = '', size = '') => {
  const colorExists = color !== '';
  const sizeExists = size !== '';

  if (!colorExists) return '';

  if (color === 'dark-blue') {
    return 'bg-dark-blue text-white';
  }

  return sizeExists ? `${size}:bg-${color}` : `bg-${color}`;
};

export default getBackgroundColor;
