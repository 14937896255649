import React from 'react';
import Typed from 'react-typed';

const VerticalWordsAnimation = React.memo(function VerticalWordsAnimation(props) {
  try {
    const wordsStr = props.heading.match(/data-words="(.*)"/)[1];
    const words = wordsStr.split('|');
    const longestWord = words.reduce((a, b) => a.length > b.length ? a : b);
    return (
      <div {...props} className={`${props.className}`}>
        <div className="w-full" dangerouslySetInnerHTML={{ __html: props.heading }} style={{ maxWidth: '600px' }} />
        <div className="relative">
          <div className="absolute inset-0">
            <Typed strings={words} typeSpeed={60} backSpeed={50} loop className="flex justify-start text-accent">
              <h1 className="text-accent"></h1>
            </Typed>
          </div>
          <div className="opacity-0">{longestWord}</div>
        </div>
      </div>
    );
  } catch (error) {
    return null;
  }
}, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return prevProps?.heading === nextProps?.heading;
}

export default VerticalWordsAnimation;
