import React, { useEffect } from 'react';
import { storyblokEditable } from '@storyblok/react';
import Link from 'next/link';
import Image from 'next/image';
import useSpanHeading from '~/hooks/useSpanHeading';
import useBlogPosts, { useFeaturedPosts } from '~/hooks/useBlogPosts';
import BlogPostPreview from '~/components/BlogPostPreview';

export default function DottedGrid({ blok }) {
  const posts = useBlogPosts();
  const featuredPosts = useFeaturedPosts({
    random: true
  });

  const bigPost = featuredPosts[0];
  const smallPosts = blok?.posts?.map(p => posts?.filter(i => i.id === p.id)[0]);

  return (
    <section {...storyblokEditable(blok)}>

      {blok?.heading?.length > 0 ? <h2 className="u-h2 mb-12 lg:mb-16">{blok?.heading}</h2> : null}

      <div className="lg:hidden grid gap-8 sm:grid-cols-2">
        {[bigPost, ...smallPosts].map((post, i) => (
          <BlogPostPreview {...post} key={`post${i}`} />
        ))}
      </div>
      <div className="hidden lg:flex flex-wrap -ml-20 lg:-ml-24">
        <div className="pl-20 lg:pl-24 w-full md:w-1/2">
          <BigPost {...bigPost} {...bigPost?.content} darkenLargePostBackground={blok?.darkenLargePostBackground} />
        </div>
        <div className="relative pl-20 lg:pl-24 w-full md:w-1/2 flex flex-col justify-between py-6">
          <div className="hidden md:block absolute inset-y-0 left-10 lg:left-12 h-full" style={{ borderLeft: '1px dashed rgba(0,0,0,0.15)' }} />
          {smallPosts?.map((post, i) => (
            <React.Fragment key={`post${i}`}>
              <div >
                <SmallPost {...post} {...post?.content} />
              </div>
              {i !== smallPosts.length - 1 ? <div className="hidden md:block w-full pb-8 mb-8" style={{ height: '1px', borderBottom: '1px dashed rgba(0,0,0,0.15)' }} /> : null}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
}

function BigPost({ full_slug, title, previewImage, category, readingTime, darkenLargePostBackground = false,  ...other }) {
  return (
    <Link href={`/${full_slug}`} {...other}>
      <a className="relative h-full w-full flex flex-col transition-opacity duration-200 ease-in-out hover:opacity-90">
        <Image className="w-full h-full object-cover" src={previewImage?.filename || '/'} layout="fill" alt={title} />

        {darkenLargePostBackground ? (
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" ></div>
        ) : null}


        <div className="relative z-10 p-8 lg:p-12 text-white h-full w-full flex flex-col justify-end">
          <div className="flex flex-col mt-24">
            <div className="space-y-4">
              {category?.name?.length > 0 && readingTime?.length > 0 ? (
                <p className="inline-block">
                  {category?.name}
                  {readingTime && ` — ${readingTime}`}
                </p>
              ) : null}
              <h3 className="font-medium u-h3 mr-4" dangerouslySetInnerHTML={{ __html: useSpanHeading(title, 'underswipe') }} />
            </div>
            <div className="flex-auto flex items-end">
              <div className="mt-6 text-link text-link--yellow">Read More</div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}

function SmallPost({ full_slug, title, previewImage, category, readingTime, ...other }) {
  return (
    <Link href={`/${full_slug}`} {...other}>
      <a className="flex transition-opacity duration-200 ease-in-out hover:opacity-75">
        <div className="w-full" style={{ flex: '0 0 122px', maxWidth: '122px' }}>
          <div className="relative w-full bg-accent" style={{ height: '93px' }}>
            <Image className="w-full h-full object-cover" src={previewImage?.filename || '/'} layout="fill" alt={title} />
          </div>
        </div>
        <div className="ml-6 flex-auto flex flex-col justify-center">
          <div className="space-y-2">
            <h3 className="font-medium u-p1 mr-8 leading-tight" dangerouslySetInnerHTML={{ __html: useSpanHeading(title, 'underswipe') }} />
            {category?.name?.length > 0 && readingTime?.length > 0 ? (
              <p className="inline-block">
                {category?.name}
                {readingTime && ` — ${readingTime}`}
              </p>
            ) : null}
          </div>
        </div>
      </a>
    </Link>
  );
}
