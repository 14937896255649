import React from 'react'
import { storyblokEditable } from '@storyblok/react';
import IconListItem from '~/components/Storyblok/elements/IconListItem';

export default function IconList({ blok }) {
  return (
    <div {...storyblokEditable(blok)}>
      {blok.items.map(item => (
        <IconListItem key={item._uid} blok={item} />
      ))}
    </div>
  )
}
