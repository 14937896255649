import { create } from 'zustand';
import { getStoryblokApi } from '@storyblok/react';
import Cookies from 'js-cookie';
import getCachedStates from '~/utils/fetch-cached-states';

const useStore = create((set, get) => ({
  page: null,
  setPage: page => {
    set(() => ({ page }));
  },

  blogCategories: [],
  fetchBlogCategories: async () => {
    if (get().blogCategories?.length > 0) return;

    const storyblokApi = getStoryblokApi();
    const { data: { stories } } = await storyblokApi.get(`cdn/stories`, {
      by_slugs: '*/category/*',
      is_startpage: false
    });

    set(() => ({ blogCategories: stories }));
  },

  modalOpen: null,
  setModalOpen: str => {
    set(() => ({ modalOpen: str }));
  },

  pricingBlocksDisclaimerShown: false,
  setPricingBlocksDisclaimerShown: bool => {
    set(() => ({ pricingBlocksDisclaimerShown: bool }));
  },

  userStateId: Cookies.get('pricing_state'),
  setUserStateId: async () => {
    if (get().userStateId) {
      return;
    }
    const states = await getCachedStates();
    const response = await fetch('https://geo.proofserve.com/ip');
    const data = await response.json();

    let matchingState = data && data.state ? states.filter(state => state.abbreviation === data.state).pop() : {};

		Cookies.set('pricing_state', matchingState?.id || 1);
    set(() => ({ userStateId: matchingState?.id || 1 }));
  }
}));

export default useStore;
