import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import IconListItem from '~/components/Storyblok/elements/IconListItem';
import chunk from 'lodash/chunk';

export default function DottedGrid({ blok }) {
  const borderColor = blok?.borderColor?.color || '#000';

  const layout = blok.layout || '3-col-default';

  const columns = !layout || layout === '3-col-default' ? 3 : 4;
  const rows = chunk(blok.items, columns);

  return (
    <div className="" {...storyblokEditable(blok)}>
      {rows.map((cols, rowIndex) => (
        <div key={`dg${rowIndex}`} className="flex flex-wrap md:-mr-8">
          {cols.map((item, colIndex) => (
            <div key={item._uid} className={`w-full md:w-1/${columns} flex flex-col mb-4 md:mb-0`}>
              <div
                className={`${colIndex !== cols.length - 1 ? '' : ''} md:pr-8 md:mr-8 md:border-r border-dashed ${layout === '4-col-default' ? 'h-full flex flex-col justify-center' : ''}`}
                style={{ borderRightColor: colIndex !== cols.length - 1 ? borderColor : 'transparent' }}>
                <IconListItem blok={item} layout="block" />
              </div>
            </div>
          ))}

          {rowIndex !== rows.length - 1 ? <div className="hidden md:block w-full mr-8 pr-8 pb-8 mb-8" style={{ height: '1px', borderBottom: `1px dashed ${borderColor}` }} /> : null}
        </div>
      ))}
    </div>
  );
}
