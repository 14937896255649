import cookies from 'browser-cookies'; // https://www.npmjs.com/package/browser-cookies
import queryString from 'query-string'; // https://www.npmjs.com/package/query-string
// supported parameters
const utmParameters = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_name',
  'utm_term',
  'referrer' // special case
];

export default class Utm {
  constructor(options = {}) {
    if (typeof window === 'undefined') return;

    this.expires = options.expires || 365;
    this.domain = window.location.hostname ? ('.' + window.location.hostname.replace(/^www\./, '')) : 'localhost';
    this.prefix = 'utm_toolkit_';
  }
  /**
   * Store utm_parameters to cookie
   */
  store() {
    try {
      // `window.location`
      const search = window.location.search;
      const searchAsObject = queryString.parse(search);
      utmParameters.forEach(parameterName => {
        const foundValue = searchAsObject[parameterName];
        if (foundValue) {
          cookies.set(`${this.prefix}${parameterName}`, foundValue, {
            expires: this.expires,
            domain: this.domain
          });
        }
      });
      // referrer
      const referrer = window.document.referrer;
      if (referrer) {
        cookies.set(`${this.prefix}referrer`, referrer, {
          expires: this.expires,  
          domain: this.domain
        });

        let hostname = referrer;

        let sourceURL = searchAsObject['utm_source'];
        let mediumURL = searchAsObject[`utm_medium`];
        if (!sourceURL && !mediumURL) {
          cookies.set(`${this.prefix}utm_source`, 'google', {
            expires: this.expires,
            domain: this.domain
          });
          cookies.set(`${this.prefix}utm_medium`, 'organic', {
            expires: this.expires,
            domain: this.domain
          });
          cookies.set(`${this.prefix}utm_content`, window.location.href, {
            expires: this.expires,
            domain: this.domain
          });
          cookies.erase(`${this.prefix}utm_campaign`);
        }
      }
    } catch (e) {
      console.log('[Utm.store] error', e);
    }
  }
  /**
   * Read utm_parameters from cookie
   * @returns Object
   */
  get() {
    try {
      // `window.location`
      const returnValue = {};
      utmParameters.forEach(parameterName => {
        const cookieKey = `${this.prefix}${parameterName}`;
        const foundValue = cookies.get(cookieKey);
        if (foundValue) {
          returnValue[parameterName] = foundValue;
        }
      });
      return returnValue;
    } catch (e) {
      console.log('[Utm.store] error', e);
    }
  }
  /**
   * Clear utm_parameters from cookie
   */
  clear() {
    try {
      const returnValue = {};
      utmParameters.forEach(parameterName => {
        const cookieKey = `${this.prefix}${parameterName}`;
        cookies.erase(cookieKey);
      });
      return returnValue;
    } catch (e) {
      console.log('[Utm.clear] error', e);
    }
  }
}
