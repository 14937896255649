import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import Media from '~/components/Storyblok/elements/Media';

export default function IconText(props) {
  if (props.blok) {
    props = props.blok;
  }
  let { heading, headingClassNames, icon, iconWrapperWidth, iconWidth, removeSpacing=false, containerClasses='',iconClasses='' } = props;

  return (
    <div className={`w-full flex ${containerClasses} ${iconWrapperWidth ? 'flex-col md:flex-row': 'flex-row'}  items-center gap-3 ${removeSpacing ?'' : `mt-10 mb-2`}`} {...storyblokEditable(props)}>
      {icon && (
        <div className={iconClasses} style={{flex: iconWrapperWidth ?`0 0 ${iconWrapperWidth}`:'', width: iconWrapperWidth || 'auto'}}>
          <div className={iconWrapperWidth ? 'mx-auto' :''} style={{ maxWidth:'100%', width: iconWidth || '38px', height: iconWidth ? 'auto' : '38px' }}>
            <Media className="block" blok={{ ...icon, asset: icon }} />
          </div>
        </div>
      )}
      {heading && (
        <div className={`${!iconWrapperWidth ? 'flex-1': ''}`}>
          <div className={`${headingClassNames || 'u-h3'}`}>{heading}</div>
        </div>
      )}
    </div>
  );
}

// !items-start