import NodeCache from 'node-cache';

const statesCache = new NodeCache({ stdTTL: 3600 });

const getCachedStates = async () => {
  if (statesCache.has('states')) {
    return statesCache.get('states');
  }

  const req = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/states`);
  const data = await req.json();
  statesCache.set('states', data);
  return data;
};

export default getCachedStates;
