import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

const LandingPage = ({ blok }) => {
  return (
    <section {...storyblokEditable(blok)}>
      <StoryblokComponent blok={blok.landingPageBlock[0]} background={blok?.background} />
    </section>
  );
};

export default LandingPage;
