import React from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

export default function TwoColumns({ blok }) {
  const { columns = [], verticalAlignment = '' } = blok;
  return (
    <div className={`flex flex-wrap ${verticalAlignment}`} {...storyblokEditable(blok)}>
      {columns.map(column => (
        <StoryblokComponent blok={column} key={column._uid} />
      ))}
    </div>
  );
}
