import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import RenderRichText from '~/components/Storyblok/RenderRichText';
import Media from '~/components/Storyblok/elements/Media';
// import Image from 'next/image';

export default function IconListItem({ blok, layout = 'inline' }) {
  const hasHeading = blok?.heading?.length > 0;

  return (
    <div
      className={`flex ${blok.className||''} ${layout === 'block' ? 'flex-col' : 'mt-4 border-t border-dashed '}  text-center md:text-left ${blok?.removePaddingTop ? 'pt-0' : 'pt-4'}`} style={{ borderColor: 'rgba(0,0,0,0.1)' }} {...storyblokEditable(blok)}>
      {!!blok?.icon?.filename && (
        <div className={`${layout === 'inline' ? 'mt-2 mr-6' : 'mb-3 md:mb-6 mx-auto md:ml-0'} ${blok?.containerClasses}`} style={{ flex: `0 0 ${blok?.iconWrapperWidth || '20px'}`, maxWidth: layout === 'block' ? (blok?.iconWrapperWidth || '20px') : '', width:layout ==='block'? '100%': (blok?.iconWrapperWidth || '20px') }}>
          <Media className={`block ${layout === 'block' ? 'max-w-[20px]' : ''} ${blok?.iconClasses}`} blok={{ ...blok.icon, asset: blok.icon }} />
        </div>
      )}

      {hasHeading ? <h3 className="text-18px font-semibold mb-1">{blok.heading}</h3> : null}

      <div className={`flex-auto  ${blok.contentClassName || (hasHeading ? '' : 'u-p2')}`}>
        <RenderRichText content={blok.content} />
      </div>
    </div>
  );
}
