import React from 'react'
import { storyblokEditable } from '@storyblok/react';
import RenderRichText from '~/components/Storyblok/RenderRichText';
import { createGlobalStyle } from 'styled-components';

const TickListStyled = createGlobalStyle`
.tick-tick {
  display: flex;

  .text-card.text-center & {justify-content: center;}
  .text-card.text-right & {justify-content: flex-end;}

  &.font-size-default {font-size:1em;}
  &.font-size-16px {font-size:16px;}
  &.font-size-14px {font-size:14px;}

  &.display-block {
    ul {
      flex-direction: column;
      li {
      line-height:1.33;
        &:before {
          height:32px;
          width:1.8rem;
          flex:0 0 1.8rem;
        }
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left:-1.5em;
    li {
      padding-left:1.5em;
      padding-bottom:.33em;
      display: flex;
      align-items: center;
      &:before {
        content:'';
        display:inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='1em' height='100%' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.88 6.197l3.98 3.27S6.344 4.483 13.28.813' stroke='%23F3B545' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 0% 53%;
        height:100%;
        width:1.33rem;
        flex:0 0 1.33rem;
      }
    }
  }


}
`;

export default function TickList(blok) {
  if (blok.blok) blok = blok.blok;

  return (
    <div {...storyblokEditable(blok)}>
      <TickListStyled />
      <div className={`tick-tick display-${blok.display} font-size-${blok.fontSize}`}>
        <RenderRichText content={blok.listItems} />
      </div>
    </div>
  );
}
