import React from 'react';
import styled from 'styled-components';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import getBackgroundColor from '~/utils/get-background-color';

const ColumnInner = styled.div`
  max-width: ${props => props.maxWidth || '100%'};
  padding: ${props => props.padding};
  margin: ${props => props.margin};

  @media screen and (min-width: 1020px) {
    max-width: ${props => props.desktopMaxWidth || props.maxWidth};
    padding: ${props => props.desktopPadding || props.padding};
    margin: ${props => props.desktopMargin || props.margin};
  }
`;

export default function Column({ blok }) {
  const {
    content = [],
    horizontalAlignment = '',
    maxWidth = '100%',
    desktopMaxWidth = '',
    padding = 0,
    desktopPadding = 0,
    margin = 0,
    desktopMargin = 0,
    background = '',
    rounded = false,
    shadow = false,
    className = '',
    innerClassName = ''
  } = blok;

  return content.length > 0 ? (
    <div
      className={`flex-auto w-full flex flex-col ${className} ${horizontalAlignment} ${getBackgroundColor(background)} ${rounded ? 'rounded-md' : ''} ${shadow ? 'shadow-std' : ''}`}
      {...storyblokEditable(blok)}>
      <ColumnInner className={`flex-auto w-full ${innerClassName}`} padding={padding} desktopPadding={desktopPadding} margin={margin} desktopMargin={desktopMargin} maxWidth={maxWidth} desktopMaxWidth={desktopMaxWidth}>
        {content.map(nestedBlok => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </ColumnInner>
    </div>
  ) : null;
}
