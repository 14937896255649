import React from 'react';
import Image from 'next/image';
import { storyblokEditable } from '@storyblok/react';
import Modal from '~/components/Storyblok/elements/Modal';
import LottieAnimation from '~/components/LottieAnimation';
import useStore from '~/hooks/useStore';
import dynamic from 'next/dynamic';
import Head from 'next/head';

export default function Media({ blok, className = '', layout, objectFit, objectPosition }) {
  const {
    asset = {},
    modal = [],
    containerClasses = '',
    videoElementClasses = '',
    imgElementClasses = '',
    useNativeImgElement = false,
    addVideoSound = false,
    addVideoControls = false
  } = blok;
  if (!asset?.filename) return null;

  const dimensions = {
    width: asset.filename.split('/')[5].split('x')[0] || '100%',
    height: asset.filename.split('/')[5].split('x')[1] || '100%'
  };

  const hasModal = modal?.length > 0;
  const hideModalPlayButton = hasModal ? modal[0]?.hidePlayButton === true : false;
  const setModalOpen = useStore(state => state.setModalOpen);
  const isImage = /(png|jpg|jpeg|gif|avif|webp|svg)$/gi.test(asset.filename);
  const isVideo = /(mp4|mov|webm)$/gi.test(asset.filename);
  const isJsonLottie = /(\.json)$/gi.test(asset.filename);
  const isDotLottie = /(\.lottie)$/gi.test(asset.filename);

  if (isDotLottie) {
    dynamic(import('@johanaarstein/dotlottie-player'));
  }

  return (
    <>
      <div className={`w-full relative ${className} ${containerClasses}`} style={{ maxWidth: blok.maxWidth || '100%' }} {...storyblokEditable(blok)}>
        {isImage ? (
          <>
            {!useNativeImgElement ? (
              <Image
                className={`block ${imgElementClasses}`}
                src={asset.filename}
                alt={asset.alt || asset.filename || ''}
                layout={layout || `responsive`}
                {...dimensions}
                objectFit={objectFit}
                objectPosition={objectPosition}
              />
            ) : (
              <img src={asset.filename} alt={asset.alt || asset.filename || ''} className={`block ${imgElementClasses}`} />
            )}
          </>
        ) : null}

        {isVideo ? (
          <>
            <Head>
              <link key={asset.filename} rel="preload" fetchpriority="high" href={asset.filename} as="video" type="video/mp4" />
            </Head>

            <video className={videoElementClasses} src={asset.filename} playsInline autoPlay muted={!addVideoSound} loop={!addVideoSound} controls={addVideoControls} />
          </>
        ) : null}

        {isDotLottie ? <dotlottie-player src={asset.filename} autoplay loop style={{ height: '100%', width: '100%' }} /> : null}

        {isJsonLottie ? <LottieAnimation lottieFile={asset.filename} /> : null}

        {hasModal && (
          <div
            className="fadeIn80 absolute inset-0 flex flex-col items-center justify-center opacity-80 hover:opacity-100 transition-opacity duration-150 cursor-pointer"
            onClick={() => setModalOpen(modal[0].id)}>
            {!hideModalPlayButton ? (
              <svg width="55" height="55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 6a6 6 0 0 1 6-6h43a6 6 0 0 1 6 6v43a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6z" fill="#F3B545" />
                <path d="M38 28l-16.5 9.526V18.474L38 28z" fill="#fff" />
              </svg>
            ) : null}
          </div>
        )}
      </div>

      {hasModal ? <Modal blok={blok?.modal[0]} /> : null}
    </>
  );
}
