import React from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

export default function FourColumns({ blok }) {
  const { columns = [], maxWidth='', horizontalAlignment='' } = blok;

  return (
    <div className={`flex flex-col ${horizontalAlignment === 'items-center' ? 'mx-auto': ''} w-full`} style={{ maxWidth }}>
      <div className={`w-full grid sm:grid-cols-2 md:grid-cols-4 gap-4`} {...storyblokEditable(blok)}>
        {columns.map((column, i) => (
          <div key={`for-cols-col${i}`} className={`flex flex-col`}>
            <StoryblokComponent blok={column} />
          </div>
        ))}
      </div>
    </div>
  );
}
