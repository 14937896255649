import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const loadHubspot = callback => {
  if (typeof window === 'undefined') return null;
  const existingScript = !!window.hbspt;
  const fire = () => {
    if (window.hbspt) {
      callback();
    } else {
      console.log('Loading hbspt failed');
    }
  };
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.onload = () => {
      fire();
    };
  }
  if (existingScript) {
    fire();
  }
};

const FormContainer = styled.div`
  form {
    width: 100% !important;
  }

  input {
    margin-top: 0.3em;
    height: 3.3rem;
    &::placeholder {
      opacity: 0 !important;
      display: none !important;
    }
    &::placeholder-shown {
      opacity: 0 !important;
      display: none !important;
    }
    &.error {
      border-color: var(--accent);
    }
  }

  .hs-form-field {
    margin-bottom: 1.3rem;
  }

  .hs-error-msg {
    color: var(--red);
    font-size: 0.8rem !important;
  }
`;

export default function HubspotForm({ placeholderHeight = 300, hubspotFormId = '', appendToId = '', region = 'na1', portalId = '20301509', onReady = null }) {
  const [formContainerId] = useState(`form-${hubspotFormId}-${Math.random().toString(36).substr(2, 9)}`);
  const ref = useRef(null);

  useEffect(() => {
    if (typeof window === 'undefined' || !ref.current) return undefined;

    loadHubspot(() => {
      hbspt.forms.create({
        region,
        portalId,
        formId: hubspotFormId,
        target: '#' + ref.current.id,
        onFormReady($form) {
          if (onReady) {
            onReady(`#form-${hubspotFormId}`);
          }
        }
      });
    });
  }, [ref]);
  return (
    <FormContainer>
      <div ref={ref} className="hubspot-form" id={formContainerId}></div>
    </FormContainer>
  );
}
